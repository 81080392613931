import React from "react";
import "./styles.scss";

export default function Team() {

    const teamMembers = [
        {
            name: "Chuck",
            jobTitle: "Lead Developer",
            funFact: "Loves collecting vintage watches.",
            image: require("../../assets/team/image-6.png"),
        },
        {
            name: "Aman",
            jobTitle: "Marketing Manager",
            funFact: "Is an expert in juggling.",
            image: require("../../assets/team/image-2.png"),
        },
        {
            name: "Celine",
            jobTitle: "UI/UX Designer",
            funFact: "Has traveled to over 30 countries.",
            image: require("../../assets/team/image-4.png"),
        },
        {
            name: "Honoure",
            jobTitle: "Data Scientist",
            funFact: "Is a professional salsa dancer.",
            image: require("../../assets/team/image-3.png"),
        },
        {
            name: "Layla",
            jobTitle: "Product Manager",
            funFact: "Can solve a Rubik's cube in under 30 seconds.",
            image: require("../../assets/team/image-5.png"),
        },
    ];

    return (
        <section className="teamWindow">
            <div className="scrollable teamWindow__body">
                <h1>Our Team</h1>
                <div className="teamWindow__bodyInner">
                    {teamMembers.map((member, index) => (
                        <div className="teamMember" key={index}>
                            <img
                                src={member.image}
                                alt={`${member.name} profile`}
                                className="teamMember__image"
                            />
                            <div className="teamMember__info">
                                <h2>{member.name}</h2>
                                <h3>{member.jobTitle}</h3>
                                <p><strong>Fun Fact:</strong> {member.funFact}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
