import React from "react";

export default function TaskbarLanguage() {
    const handleClick = () => {
        // Here you can implement language change logic
        console.log("Language button clicked");
    };

    return (
        <button onClick={handleClick} className="taskbarLanguage">
            EN
        </button>
    );
}
