import React from "react";
import { Tabs, Tab, TabBody } from "react95";
import "./styles.scss";

export default function Apps() {

    const [state, setState] = React.useState({
        activeTab: 0
    });

    const handleChange = (value) => {
        setState({ activeTab: value });
    };

    const { activeTab } = state;

    return (
        <section className="appsWindow">
            <div>
                <Tabs value={activeTab} onChange={handleChange}>
                    <Tab value={0}>Instapeek</Tab>
                    <Tab value={1}>Freshy</Tab>
                    <Tab value={2}>Livelens</Tab>
                </Tabs>
                <TabBody style={{ height: "70vh" }}>
                    {activeTab === 0 && (
                        <div className="scrollable appsWindow__details">
                            <div className="appsWindow__item">
                                <img
                                    src={require("../../assets/media/image-13.png")}
                                    alt="Instapeek screenshot"
                                    className="app-image"
                                    style={{ width: '200px', height: 'auto' }}
                                />
                                <div className="app-description">
                                    <p>
                                        <strong>Instapeek:</strong> Instapeek is an app that helps you gain hidden insights from your Instagram account. You can securely log in via our app, follow a guided process to download your Instagram data, and view detailed information such as your previously sent pending follow requests, accounts that don't follow you back, sent story like counts, and much more.
                                    </p>
                                    <p>
                                        You securely log in to your Instagram account within the Instapeek app. Then, follow the step-by-step guide to download your Instagram data. After the data is downloaded, Instapeek extracts and processes it to give you the requested insights.
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === 1 && (
                        <div className="scrollable appsWindow__details">
                            <div className="appsWindow__item">
                                <img
                                    src={require("../../assets/media/image-15.png")}
                                    alt="Freshy screenshot"
                                    className="app-image"
                                    style={{ width: '200px', height: 'auto' }}
                                />
                                <div className="app-description">
                                    <p>
                                        <strong>Freshy:</strong> Freshy is a mobile application that uses advanced AI technology to determine the freshness of food items like fruits, vegetables, meat, and fish by analyzing photos taken by the user.
                                    </p>
                                    <p>
                                        Simply take a photo of your food item, and Freshy's AI model will analyze the image to determine its freshness level. It will tell you whether the item is fresh or spoiled and provide tips on how to best preserve it.
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === 2 && (
                        <div className="scrollable appsWindow__details">
                            <div className="appsWindow__item">
                                <img
                                    src={require("../../assets/media/image-12.png")}
                                    alt="Livelens screenshot"
                                    className="app-image"
                                    style={{ width: '200px', height: 'auto' }}
                                />
                                <div className="app-description">
                                    <p>
                                        <strong>Livelens:</strong> Currently in development. Stay tuned for updates!
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </TabBody>
            </div>
        </section>
    );
}
