import React from "react";
import "./styles.scss";
import { Tabs, Tab, TabBody } from "react95";

export default function LegalDocs() {

    const [state, setState] = React.useState({
        activeTab: 0
    });

    const handleChange = (value) => {
        setState({ activeTab: value });
    };

    const { activeTab } = state;

    return (
        <section className="legalDocsWindow">
            <div>
                <Tabs value={activeTab} onChange={handleChange}>
                    <Tab value={0}>Terms & Conditions</Tab>
                    <Tab value={1}>Privacy Policy</Tab>
                </Tabs>
                <TabBody style={{ height: "70vh" }}>
                    {activeTab === 0 && (
                        <div className="scrollable legalDocsWindow__details">
                            <h2>Terms & Conditions</h2>
                            <p><strong>Updated September 2024</strong></p>

                            <h3>Acceptance of Terms</h3>
                            <p>This document governs the use of InstaPeek (“the Application”), which provides Instagram analytics and insights. By downloading, accessing, or using InstaPeek, you agree to abide by these terms. If you do not agree, please discontinue use of the app immediately.</p>

                            <h3>Data Collection and Privacy</h3>
                            <p>We do not collect, store, or access any personal data from our users. All data processed by InstaPeek remains solely on your device, ensuring maximum privacy and security. The app does not upload or share any user data with external servers or third parties.</p>

                            <h3>Payment and Premium Features</h3>
                            <p>The basic features of InstaPeek are free, but advanced features are available via a subscription. Payments are handled by third-party services, such as Apple’s App Store or Google Play.</p>

                            <ul>
                                <li>Payments are automatically charged at the start of each subscription period.</li>
                                <li>Subscriptions renew automatically unless canceled 24 hours prior to the renewal date.</li>
                                <li>You may manage your subscription through your app store settings at any time.</li>
                                <li>Pricing may change, and users will be informed beforehand to adjust their subscriptions accordingly.</li>
                            </ul>

                            <h3>Refund Policy</h3>
                            <p>All payments made via the app stores are final. Users seeking refunds must contact the respective app store customer service (e.g., Apple or Google Play).</p>

                            <h3>Liability Limitations</h3>
                            <p>InstaPeek and its creators are not liable for any direct, indirect, incidental, or consequential damages arising from the use of the Application. Users assume full responsibility for their actions while using the Application.</p>

                            <h3>Changes to Terms</h3>
                            <p>We may update the terms and conditions periodically. Continued use of the app implies acceptance of these updates.</p>

                            <h3>Governing Law</h3>
                            <p>These terms shall be governed in accordance with the laws of the jurisdiction in which InstaPeek operates. Any disputes shall be handled within the jurisdiction’s courts.</p>
                        </div>
                    )}

                    {activeTab === 1 && (
                        <div className="scrollable legalDocsWindow__details">
                            <h2>Privacy Policy</h2>
                            <p><strong>Updated September 2024</strong></p>

                            <h3>Information Collection and Storage</h3>
                            <p>We do not collect, store, or access any personal information from our users. All data processed by the Application stays on the user’s device, ensuring maximum privacy and security. This includes Instagram account data, personal identifiers, and any insights generated by the app.</p>

                            <h3>Automatically Collected Information</h3>
                            <p>The Application may collect usage data directly on your device, such as access dates, times, and actions within the app. This information remains local on your device and is not transmitted to InstaPeek or any third party.</p>

                            <h3>Data Security</h3>
                            <p>InstaPeek employs industry-standard security practices to protect the integrity of the app and its functionality on your device. All Instagram interactions are done securely within the application environment.</p>

                            <h3>Children’s Privacy</h3>
                            <p>InstaPeek does not knowingly collect or store any data from children under 13. Since no data is collected, this policy applies universally across all users.</p>

                            <h3>Changes to This Privacy Policy</h3>
                            <p>We may update our privacy policy periodically to reflect changes in our practices. The “Updated” date at the top of this policy will be revised to indicate any changes.</p>

                            <h3>Your Consent</h3>
                            <p>By using the InstaPeek Application, you agree to the terms of this Privacy Policy.</p>

                            <h3>Contact Us</h3>
                            <p>If you have any questions or concerns regarding our privacy practices, you can contact us at support@instapeek.app.</p>
                        </div>
                    )}
                </TabBody>
            </div>
        </section>
    );
}
