const about = {
    label: "Algebra",
    header: "Algebra",
    desktopIcon: require(`../assets/icons/start.png`),
    desktopPosition: 1,
    visibility: [true, true],
};

const apps = {
    label: "Apps",
    header: "Apps",
    desktopIcon: require(`../assets/icons/apps.png`),
    desktopPosition: 2,
    visibility: [false, false],
};

const team = {
    label: "Team",
    header: "Team",
    desktopIcon: require(`../assets/icons/team.png`),
    desktopPosition: 3,
    visibility: [false, false],
};

const legal = {
    label: "Legal",
    header: "Legal",
    desktopIcon: require(`../assets/icons/blog.png`),
    desktopPosition: 4,
    visibility: [false, false],
};

export const WINDOW_OBJ = {
    about,
    apps,
    team,
    legal,
};