import React from "react";

import "./styles.scss";

export default function About() {

    return (
        <section className="aboutWindow">
            <div className="scrollable aboutWindow__body">
                <h1>Welcome to Algebra Fund!</h1>
                <div className="aboutWindow__bodyInner">
                    <div className="description">
                        <p>
                            Algebra Fund is a mobile app development company, founded in 2023, specializing in cutting-edge AI and user-centric solutions.
                        </p>
                        <br />
                        <p>
                            Our portfolio currently includes three innovative applications:
                        </p>
                        <ul>
                            <li><strong>Freshy: AI Freshness Identifier</strong> – Detects food freshness and provides preserving instructions and meal suggestions.</li>
                            <li><strong>Instapeek: Hidden Instagram Insights</strong> – Reveals hidden Instagram data, such as pending follow requests and more.</li>
                            <li><strong>Livelens: Realtime Cam Translator</strong> – Provides real-time translation by detecting text on the screen via the camera.</li>
                        </ul>
                        <br />
                        <p>
                            Our team of developers, marketing managers, and creatives work tirelessly to build apps that provide real value to our users.
                        </p>
                    </div>
                </div>

            </div>
        </section>
    );
}
