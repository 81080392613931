import React from "react";
import { useRecoilState } from "recoil";
import { Button, MenuList, MenuListItem } from 'react95';
import "./styles.scss";

import { windowObj } from "../../store/atoms";

export default function StartMenu() {
    const [isOpen, setOpen] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const [, setWindows] = useRecoilState(windowObj);
    const refMenu = React.useRef(undefined);

    const toggleMenu = (toggle) => {
        setOpen(toggle);
        if (toggle) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    };

    const handleButtonClick = () => {
        toggleMenu(!isOpen);
    };


    const handleClickOutside = ({ target }) => {
        if (refMenu.current.contains(target)) return;
        toggleMenu(false);
    };

    return (
        <div className="startMenu" ref={refMenu}>
            <div className={`startMenu__slide${isOpen ? " -isOpen" : ""}`}>
                <MenuList
                    horizontalAlign="left"
                    verticalAlign="top"
                    className="startMenu__menu"
                >
                    <a href="https://twitter.com/your_twitter_handle" target="_blank" rel="noreferrer">
                        <MenuListItem data-name="twitter">
                            <p className="startMenu__menuItem">
                                <img
                                    src={`${require("../../assets/icons/socials.png")}`}
                                    alt=""
                                    width="30"
                                    className="pixelated"
                                />
                                Twitter
                            </p>
                        </MenuListItem>
                    </a>
                    <a href="https://www.instagram.com/your_instagram_handle" target="_blank" rel="noreferrer">
                        <MenuListItem data-name="instagram">
                            <p className="startMenu__menuItem">
                                <img
                                    src={`${require("../../assets/icons/socials.png")}`}
                                    alt=""
                                    width="30"
                                    className="pixelated"
                                />
                                Instagram
                            </p>
                        </MenuListItem>
                    </a>
                    <a href="https://www.tiktok.com/@your_tiktok_handle" target="_blank" rel="noreferrer">
                        <MenuListItem data-name="tiktok">
                            <p className="startMenu__menuItem">
                                <img
                                    src={`${require("../../assets/icons/socials.png")}`}
                                    alt=""
                                    width="30"
                                    className="pixelated"
                                />
                                TikTok
                            </p>
                        </MenuListItem>
                    </a>
                </MenuList>
            </div>
            <Button
                data-name="start-menu"
                onClick={handleButtonClick}
                active={isOpen}
                className="mr1 startMenu__triggerButton"
                size="sm"
            >
                <img
                    src={require("../../assets/win-logo.png")}
                    alt=""
                    className="pixelated startMenu__logo"
                />
                Start
            </Button>
        </div>
    );
}